import "./jquery-easings";
import "./jquery-focuspoint";
import Rellax from "rellax";
import "./iealert";
import SVGInjector from "svg-injector";
import "./bootstrap";
import "./modernizr";
import "./TweenMax";
import "./ScrollToPlugin";
import ScrollReveal from "./scrollreveal";
import "./selectivizr";
import "./underscore";
import "bootstrap-datepicker";
import Foresight from 'foresight';

var isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPod/i);
	},
	iPad: function() {
		return navigator.userAgent.match(/iPad/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS()  ||  isMobile.Opera() || isMobile.Windows());
	}
};

$(document).ready(function() {
	// Entry function
	enableLoadingAnimation();
	enableFristAnimation();
	enableTrack();
	enableMainNav();
	enableRevealAnimation();
	if (checkPage() == 'page-home') {
		enableCoffeeSlide();
		enableParallax();
		enableFoodNav();
		toggleBookingField();
		updateDateValidate();
	}
});


/**
 * Loading Animation
 */
function enableLoadingAnimation() {
	// Percentage Increment Animation
	var width = 100, // width of a progress bar in percentage
		perfData = window.performance.timing, // The PerformanceTiming interface
		EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart), // Calculated Estimated Time of Page Load which returns negative value.
		time = parseInt((EstimatedTime/1000)%60)*100; //Converting EstimatedTime from miliseconds to seconds.

	var PercentageID = $("#percentage"),
			start = 0,
			end = 100,
			duration = time;
	animateValue(PercentageID, start, end, duration);

	function animateValue(id, start, end, duration) {
		var range = end - start,
		current = start,
		increment = end > start? 1 : -1,
		stepTime = Math.abs(Math.floor(duration / range)),
		obj = $(id);

		var timer = setInterval(function() {
			current += increment;
			$(obj).text(current + "%");
			if (current == end) {
				clearInterval(timer);
			}
		}, stepTime);
	}

	// Coffee Loadbar Animation
	$(".coffee").animate({
		height: width + "%"
	}, time, "linear", function() {
		$( "body" ).addClass("loaded");
		animBox();
	});

	//Animate homepage box
	function animBox(){
	var	tl = new TimelineMax({ ease: Expo.easeOut});
		tl.fromTo($(".desc-box-top"), 0.3, {width: "0"},{width: "100%"}, "1")
		  .fromTo($(".desc-box-left"), 0.3, {height: "0"},{height: "100%"})
		  .fromTo($(".desc-box-bottom"), 0.3, {width: "0"},{width: "100%"})
		  .fromTo($(".desc-box-right"), 0.3, {height: "0"},{height: "100%"})
		  .fromTo($(".desc-box-text"), 0.8, {autoAlpha: "0"},{autoAlpha: "1"}, "-=0.3");
	}
}


/**
 * Foresight
 */
function enableTrack() {
	var foresight = new Foresight();
}


/**
 * Check Page
 */
function checkPage() {
	const body = document.querySelector('body');
	const pageClass = body.classList.value;
	return pageClass;
}

function enableFristAnimation() {

/**
 * Initialise the animation
 */
	//focuspoint
	$('.focuspoint').focusPoint();

	//Initial little coffee cup
	TweenMax.to($(".coffee-cup-logo"), 1, {autoAlpha: 1});

	// SVG inject
	var mySVGsToInject = document.querySelectorAll('.svg-container');
	SVGInjector(mySVGsToInject);
	
	//Initial Food Menu Background Img
	$(".menu-img-item--lightbites .menu-img-src").each(function(i,el){
		var imgURL = $(el).data("src");
		$(el).css("background-image", "url(" + imgURL + ")")
	});

	// Mouse & Finger aids UI
	if ( isMobile.any() ) {
		$(".finger").addClass("is-show");
	} else {
		$(".mouse").addClass("is-show");
	}
	$(window).one('scroll', function() {
		$(".finger, .mouse").removeClass("is-show");
	})
}


/**
 * Parallax animaiton
 */
function enableParallax() {	
	var rellax = new Rellax('.rellax');
}


/**
 * Main Navigation
 */
function enableMainNav() {
	var navOpen = false;
	var mainMenu = $(".mainMenu");
	
	//Menu toggle
	$('.menuBtn').click(function() {
	   
		var menuBtn = $(this),
			body = $("body"),
			nav = $("nav"),
			logo = $(".logo");
	   
	   if ( navOpen == false ){
			nav.addClass("active");
			mainMenu.addClass("active");
			menuBtn.addClass("active");
			// body.addClass("overflowHide");
			logo.addClass("active");
			navOpen = true;
	   }else {
			nav.removeClass("active");
			mainMenu.removeClass("active");
			menuBtn.removeClass("active");
			// body.removeClass("overflowHide");
			logo.removeClass("active");
			navOpen = false;
	   };
	
		//Menu button navigate to section
		$('.mainMenu a').click(function() {
			var sectionID = $(this).attr("href"),
				sectionOffset = $(sectionID).offset().top;
			if ( isMobile.any() ) {
				TweenMax.to($(window), 0.3, {scrollTo:{y:sectionOffset}, delay: 0.3});
			}else{
				TweenMax.to($(window), 0.3, {scrollTo:{y:$(sectionID).offset().top}, delay: 0.3});
			}
	
			nav.removeClass("active");
			mainMenu.removeClass("active");
			menuBtn.removeClass("active");
			body.removeClass("overflowHide");
			logo.removeClass("active");
			navOpen = false;
			return false;
		});
	   
	   return false;
	});
}


/**
 * Food Menu navigation
 */
function enableFoodNav() {
	//Initial animation default state
	var animating = false;
	TweenMax.fromTo($(".menu-img-item")[0], 0.5, {zIndex:1}, {x: "0%"});
	TweenMax.to($(".menu-img-item").eq(0).find(".menu-img-src"), 0.5, { x: "0%", scale: 1 });
	TweenMax.set($(".menu-img-item").eq(0).find(".menu-img-src"), { display: "block" });

	//Resize fit Food Menu
	var menuimgContainerPos = $(".menu-img").offset().top;
	$(window).on('resize', function(){
		//TweenMax.set($(".menu-list"), {clearProps: 'all'});
		menuimgContainerPos = $(".menu-img").offset().top;
		if ( isMobile.any() ) {
			if ( $(".menu-col").find("div").hasClass("menu-img") ) {
				return false;
			}else{
				// $(".menu-img").prependTo(".menu-container");
			}
		}
	})

	//Food Menu Click 
	$(".menu-list-item .menu--to-animate li a").click(function(){
		var $this = $(this),
		targetData = $this.data("target"),
		targetItem = $("."+targetData),
		targetImg = targetItem.find(".menu-img-src"),
		targetDesc = targetItem.find(".menu-img-desc"),
		notThisItem = $(".menu-img-item").not(targetItem),
		notThisImg = $(".menu-img-src").not(targetImg);		

		if ( animating !== true ){
			if ( isMobile.any ) {
				animateDesc();
			}
			animateMenu();
		}		

		function animateMenu(){
			animating = true;
			TweenMax.set($(".menu-list-item .menu--to-animate li a"), {className:"-=active"});
			TweenMax.set($this, {className:"+=active"});
			TweenMax.set($(".menu-img-item"), {zIndex: ""});
			TweenMax.set(targetItem, {zIndex: 1});
			// TweenMax.fromTo(targetItem, 1, {zIndex:1}, {x: "0%"});
			TweenMax.set(targetImg, {display: 'block'})

			TweenMax.to(targetItem, 0.5, {x: "0%", delay: 0.05, ease: Power2.easeInOut});
			TweenMax.to(targetImg, 0.5, { x: "0%", scale: 1, delay: 0.05, ease: Power2.easeInOut, onComplete: resetMenuPos });
		}
		
		function animateDesc() {
			TweenMax.set($(".menu-img-desc"), {autoAlpha: 0});
			TweenMax.to(targetDesc, 0.5, { autoAlpha: 1, delay: 0.05 });
			TweenMax.to($(window), 0.5, { scrollTo:{y:menuimgContainerPos}, ease:Power2.easeOut });
		}
	
		function resetMenuPos(){
			TweenMax.set($(".menu-img-src"), {display: 'none'})
			TweenMax.set(targetImg, {display: 'block'})
			TweenMax.set(notThisItem, {x: "100%"});
			TweenMax.set(notThisImg, {x: "-100%", scale: 1.1});
			animating = false;
		}
	})

	//Food Menu Category Click
	$(".menu-category-item .svg-item").each(function(i,el){
		var	target = $(el).data("target");

		$(el).click(function(){
			var $this = $(this),
			targetData = $this.data("targetimg"),
			targetItem = $("."+targetData),
			activeHeight = $(".menu-list-item.active").height(); // find current/active element height
			
			targetItem.each(function(j,item){
				var itemImg = $(item).find(".menu-img-src"),
					imgURL = itemImg.data("src");
				if ( $(item).hasClass("menu-img-item--loaded") ) {
					return false;
				} else {
					itemImg.css("background-image", "url(" + imgURL + ")");
					$(item).addClass("menu-img-item--loaded");
				}
			})

			if ( isMobile.any() ) {
				TweenMax.set($(".menu-list-item.active .menu--to-animate li"), {autoAlpha: 0, x: "-20%"});
			}else{
				// TweenMax.staggerFromTo($(".menu-list-item.active .menu--to-animate li"), 0.4, {autoAlpha: 1, x: "0%"}, {autoAlpha: 0, x: "-20%"},0.03);
				TweenMax.to($(".menu-list-item.active .menu--to-animate li"), 0.4, {autoAlpha: 0, x: "-20%"});
			}

			var targetElHeight = $("#" + target).height(); // find target element height
			$(".menu-list-item").removeClass("active");
			$("#" + target).addClass("active");
			TweenMax.fromTo($("#" + target), 0.2, {height: activeHeight}, {height: targetElHeight, clearProps: 'all'}); // animate target element height
			$(".menu-category-item .svg-item").removeClass("active");
			$(el).addClass("active");

			TweenMax.staggerFromTo($("#"+target+" .menu--to-animate.active li"), 0.3, {autoAlpha: 0, x: "10%"}, {autoAlpha: 1, x: "0%"},0.05);
		})
	})

	//Food Menu Sub Category Click
	$(".menu__subcategory a").each(function(i,el){
		var	target = $(el).data("target");

		$(el).click(function(){
			var targetElHeight = $("#" + target).height(), // find target element height
				activeHeight = $(".menu-list-item.active .menu__sublist.active").height(); // find current/active nested element height

			$(this).parents(".menu-list-item").find(".menu__sublist").removeClass("active");
			$("#" + target).addClass("active");
			TweenMax.fromTo($("#" + target), 0.2, {height: activeHeight}, {height: targetElHeight, clearProps: 'all'}); // animate target element height
			$(this).parents(".menu-list-item").find(".menu__subcategory a").removeClass("active");
			$(el).addClass("active");

			TweenMax.staggerFromTo($("#"+target+" li"), 0.3, {autoAlpha: 0, x: "10%"}, {autoAlpha: 1, x: "0%"},0.05);
		})
	})

	//Food Menu Carousel Click
	var menuAnim = false;
	$(".menu-list-mul .arrow").click(function(){
		var
		currentMenuList = $(".menu-list-item.active"),
		menuRow = currentMenuList.find(".menu-list-row"),
		menuCol = currentMenuList.find(".menu-list-col"),
		menuInner = currentMenuList.find(".menu-list-rowinner"),
		totalCol = menuCol.length,
		rowWidth = menuRow.width(),
		colWidth = menuCol.outerWidth(),
		colPosLeft = menuCol.position().left,
		col1stPosLeft = menuCol.eq(0).position().left,
		colLastPosLeft = menuCol.eq(totalCol-1).position().left,
		arrowLeft = $(".arrow-left a"),
		arrowRight = $(".arrow-right a");

		if ( menuAnim !== true ) {
			menuAnim = true;
			if ( $(this).hasClass("arrow-left") ){
				console.log(col1stPosLeft, colLastPosLeft)
				if( col1stPosLeft > -colWidth ){
					menuAnim = false;
					return false;
				}else{
					TweenMax.set(arrowLeft, {className:"+=active"});
					TweenMax.to(menuInner, 0.3, {x: '+=' + colWidth, onComplete: endAnim});
				}
			}
	
			if ( $(this).hasClass("arrow-right") ){
				console.log(col1stPosLeft, colLastPosLeft)
				if( colLastPosLeft > colWidth ){
					TweenMax.set(arrowRight, {className:"+=active"});
					TweenMax.to(menuInner, 0.3, {x: '-=' + colWidth, onComplete: endAnim});
				}else{
					menuAnim = false;
					return false;
				}
			}
		}

		function endAnim() {
			menuAnim = false;
			TweenMax.set($(".arrow a"), {className:"-=active"});
		}

	})
}


/**
 * Coffee sliding animation
 */
function enableCoffeeSlide() {
	//Select Section Index
	var $this = $(this),
		$coffeeSlider = $(".coffee-slider ul"),
		$sections = $('.section').each(function(index){
			$(this).data('sectionIndex', index); //get each Index
			$coffeeSlider.append( "<li></li>" ); //Append each li to slider
			// console.log($(this).data('sectionIndex', index))
		});


	//Coffee Slider
	var coffeeCup = $(".coffee-cup"),
		coffeeFill = $(".coffee-cup span"),
		documentHeight = $(document).outerHeight();
		
	var coffeeSlide;		
	coffeeSlide = function(){
		if ( coffeeCup.length >= 1 ) {
		var	coffeeCupOffset = coffeeCup.offset().top,
			offsetPercentage = Math.round(coffeeCupOffset/documentHeight * 105),
			coffeePercentage = coffeeCupOffset/documentHeight * 80;
			
			TweenMax.to(coffeeCup, 0.4, {top: offsetPercentage + '%'});
			TweenMax.to(coffeeFill, 0.4, {height: coffeePercentage + '%'});
		}
	};
		
	//Smoothwheel
	$(window).on('scroll', function(event){
		$('html,body').stop(true, true);
		coffeeSlide();
	});
}

/**
 * Modal
 */
$('.modal').on('shown.bs.modal', function (e) {
	const modal = $(this);
	modal.addClass('show');
	$('.modal-backdrop').addClass('show');
});
$('.modal').on('hidden.bs.modal', function (e) {
	const modal = $(this);
	modal.removeClass('show');
	$('.modal-backdrop').removeClass('show');
});


/**
 * Item reveal animation
 */
function enableRevealAnimation() {
	window.sr = ScrollReveal();

	sr.reveal('.reveal', {
		reset: true,
		viewFactor: 0.3
	});

	sr.reveal('.item-reveal', {
		reset: true,
		origin: 'top',
		viewFactor: 0.6,
		duration: 1000
	});
}


/**
 * Form - Toggle Booking Field
 */
const formset = document.querySelector('.has-formset');
const optionEL = document.querySelectorAll('.form-booking-option');

function toggleBookingField() {
	$('#ffssubject').val("");	
	$('#ffssubject').change(function(){
		const subjectInput = document.getElementById('ffssubject');
		if ($(this).val()=='Bookings'){
			subjectInput.classList.add('is-booking-selected');
			$('.form-booking').addClass('d-flex is-show');
			resetCustomValidity();
		}
		else {
			subjectInput.classList.remove('is-booking-selected');
			$('.form-booking').removeClass('d-flex is-show');	
			$('.form-booking input[type=text], .form-booking input[type=number], .form-booking select').val("");
			$('.form-booking input[type=text], .form-booking input[type=number], .form-booking select').removeClass('is-valid is-invalid');
		}   
		toggleSubValidation();
	});
}

function resetCaptcha()
{
	const $j = jQuery.noConflict();
	$j('.QapTcha').html('');
	$j('.QapTcha').QapTcha({
		autoSubmit : true,
		autoRevert : true,
		disabledSubmit : false,
		PHPfile : './Qaptcha.jquery.php',
		txtLock : '',
		txtUnlock : ''
	});
	setTimeout(function(){
		$j(".QapTcha-coffee").stop().animate({height: "0px", opacity: 1}, 300 );
	},100);
}

function resetCustomValidity() {
	const ffspaxEl = document.getElementById('ffspax');
	const ffsdateEl = document.getElementById('ffsdate');
	const ffstimeEl = document.getElementById('ffstime');
	ffspaxEl.setCustomValidity('Please provide number of pax.')
	ffsdateEl.setCustomValidity('Please provide date.')
	ffstimeEl.setCustomValidity('Please select time.')
}

// Add required attr & update validate event
function toggleSubValidation() {
	const isSelected = formset.classList.contains('is-booking-selected');

	if (isSelected) {
		optionEL.forEach(function(el){
			const name = el.getAttribute('name');
			
			el.required = true;
			el.disabled = false;
			updateValidate(el,name)
		});
	} else {
		optionEL.forEach(function(el){
			el.required = false;
			el.disabled = true;
		});
	}
}

// Validate Date on event handler
function updateDateValidate() {
	$("#ffsdate").on("change paste keyup input focus blur", function() {
		resetCaptcha();
		const el = $(this)[0];
		const name = $(this).attr('name');
		if ( name == 'ffsdate' ) {
			if (el.value == '') {
				el.setCustomValidity('Please provide date.');
				el.classList.remove('is-valid');
				el.classList.add('is-invalid');
			}
			else {
				el.setCustomValidity('');
				el.classList.remove('is-invalid');
				el.classList.add('is-valid');
			}
		}
	 });
}

function updateValidate(el,name) {
	el.addEventListener('change', function(){
		resetCaptcha();
		if ( name == 'ffspax' ) {
			if (el.value == '') {
				el.setCustomValidity('Please provide number of pax.');
				el.classList.remove('is-valid');
				el.classList.add('is-invalid');
			}
			else {
				el.setCustomValidity('');
				el.classList.remove('is-invalid');
				el.classList.add('is-valid');
			}
		}
		if ( name == 'ffstime' ) {
			if (el.value == '') {
				el.setCustomValidity('Please select time.');
				el.classList.remove('is-valid');
				el.classList.add('is-invalid');
			}
			else {
				el.setCustomValidity('');
				el.classList.remove('is-invalid');
				el.classList.add('is-valid');
			}
		}
	});
}